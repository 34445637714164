@import '@fortawesome/fontawesome-free/css/all.css';
@import 'bulma.css';

/* purgecss start ignore */

footer {
  padding: 0 !important;
  padding: 1.5rem 0 !important;
}

html,
body,
div#__next {
  height: 100%;
}

body,
div#__next {
  display: flex;
  flex-direction: column;
}

main {
  flex: 1 0 auto;
}

footer {
  flex-shrink: 0;
}

footer .content figure img {
  width: 64px;
  height: 64px;
}

.media.journey {
  display: block;
}

p.beta {
  margin-bottom: 1.5rem;
}

/* purgecss end ignore */
